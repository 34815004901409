import * as React from "react"
import Logo from '../images/Logo.js';
import Layout from "../components/layout"

// styles
const pageStyles = {
  height: "100%",
  minHeight: "100vh",
  boxSizing: "border-box",
  overflowX: "hidden",
  overflowY: "hidden",
  maxWidth: "100vw",
  fontFamily: "Roboto Mono, monospace",
  background: "#e2eaec",
  color: "#0E1212",
  padding: "10%",
  margin: 0,

}

const containerStyles = {
}

const heroStyles = {
  paddingBottom: "0.5em",
  fontSize: "3em",
  fontFamily: "Cormorant Garamond, serif", 
}

const heroBodyStyles = {
  fontSize: "1em",
}



// markup
const IndexPage = () => {
  return (
    <Layout>
      <main style={pageStyles}>
        <div style={{
          marginTop: "-5%",
          marginBottom: "6em",
        }}>
          <Logo />
        </div>
        <div style={containerStyles}>
          <div style={heroStyles} > 
            <span>Iwasawa's towers.</span> <br />
            <span>Grothendieck's geometry.</span> <br />
            <span>Fristrup's vision.</span>
          </div>
          <p style={heroBodyStyles}>
            This is Aksel Fristrup, an aspiring mathematician working in Arithmetic Geometry. I probe the fields of noncommutative Iwasawa theory and higher class field theory. Here, you'll soon find my research and expository notes.
          </p>
          <p style={heroBodyStyles, {
            marginTop: "2em",
          }}>
            Feel free to send me an email at <br />
            aksel "at" fristrup.co          
          </p>

        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
